<template>
	<div class="three_cross main">
		<ul class="tabs">
			<li
				:class="{ tab: true, active: v.route_name === $route.name }"
				v-for="(v, k) in tab"
				:key="k"
				@click="tabChange(v.route_name)"
			>
				{{ v.name }}
			</li>
		</ul>
		<div class="container">
			<router-view />
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tab: [
				{
					name: "项目汇总表",
					route_name: "three_cross_static",
				},
				{
					name: "项目报备表",
					route_name: "three_cross_report",
				},
			],
		};
	},
	methods: {
		tabChange(name) {
			if (name === this.$route.name) return;
			this.$router.replace({ name: name });
		},
	},
};
</script>

<style scoped lang="less">
.three_cross {
	/deep/ .container {
		padding: 0 !important;

		.report_content {
			height: 100%;
			display: flex;
			flex-direction: column;

			.filter {
				flex: 0 0 58px;
				margin: 0 20px 0 30px;
				border-bottom: 1px solid #eee;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.search {
					display: flex;

					.area {
						margin-left: 20px;
					}
				}
			}

			.content {
				flex: 1;
				min-height: 0;
				display: flex;
				flex-direction: column;
				padding: 0 20px;
				position: relative;

				.new_form {
					position: absolute;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #fff;
					top: 0;
					left: 0;
					right: 0;
					z-index: 99;
				}

				h3 {
					line-height: 60px;
					text-align: center;
					flex: 0 0 60px;
					font-size: 24px;
				}

				.table_info {
					flex: 0 0 38px;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				.report_table {
					flex: 1;
					min-height: 0;

					.table {
						height: calc(100% - 20px);
					}

					.el-table {
						tr.hover-row > td.el-table__cell {
							background: #fff !important;
						}
					}

					tr:hover > td {
						background: #fff;
					}

					td {
						padding: 6px 0;
					}

					.el-table .cell {
						white-space: pre-line;
					}

					input.el-input__inner {
						border: none;
						padding: 0;
						font-size: 14px;
					}
				}
			}
		}
	}
}
</style>
